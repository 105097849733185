import React, { useState, useEffect } from "react";

export default function Player4form(props) {
  const { values } = props;
  const [player3Details, setPlayer3Details] = useState({ Player3Firstname: '', Player3Lastname: '', Player3Email: '', Player3Telephone: '', Player3Telephone: '' });
  const [player4Details, setPlayer4Details] = useState({ Player4Firstname: '', Player4Lastname: '', Player4Email: '', Player4Telephone: '', Player4Telephone: '' });

  const token = localStorage.getItem("token");
  var isloggedin = false;

  if (typeof token != "undefined") {
    isloggedin = true;
  }

  const [disableplayerthreeinput, setDisablePlayerThreeInput] = useState(false);

  const PlayerThreeMembershipCheck = (ismember) => {
    values.playerthreehasmembership = ismember;
    if (ismember) {
      setDisablePlayerThreeInput(true);
    } else {
      setDisablePlayerThreeInput(false);
    }
  };

  const [disableplayerfourinput, setDisablePlayerFourInput] = useState(false);

  const PlayerFourMembershipCheck = (ismember) => {
    values.playerfourhasmembership = ismember;
    if (ismember) {
      setDisablePlayerFourInput(true);
    } else {
      setDisablePlayerFourInput(false);
    }
  };

  const updatePlayer3Data = (e) => {
    if (e.target.name == "Player3Telephone") {
      values[e.target.name] = e.target.value
        .replace(/[^\w\s]/gi, "")
        .replace(/[^0-9]/g, "");
      e.target.value = values[e.target.name];
    } else {
      values[e.target.name] = e.target.value;
    }
    setPlayer3Details({
      ...player3Details,
      [e.target.name]: values[e.target.name],
    });
  };

  const updatePlayer4Data = (e) => {
    if (e.target.name == "Player4Telephone") {
      values[e.target.name] = e.target.value
        .replace(/[^\w\s]/gi, "")
        .replace(/[^0-9]/g, "");
      e.target.value = values[e.target.name];
    } else {
      values[e.target.name] = e.target.value;
    }
    setPlayer4Details({
      ...player4Details,
      [e.target.name]: values[e.target.name],
    });
  };

  useEffect(() => {
    if (values) {
      let player3Data = {};
      if (values.Player3Firstname) {
        player3Data.Player3Firstname = values.Player3Firstname;
      }
      if (values.Player3Lastname) {
        player3Data.Player3Lastname = values.Player3Lastname;
      }
      if (values.Player3Email) {
        player3Data.Player3Email = values.Player3Email;
      }
      if (values.Player3Telephone) {
        player3Data.Player3Telephone = values.Player3Telephone;
      }
      setPlayer3Details(player3Data);

      let player4Data = {};
      if (values.Player4Firstname) {
        player4Data.Player4Firstname = values.Player4Firstname;
      }
      if (values.Player4Lastname) {
        player4Data.Player4Lastname = values.Player4Lastname;
      }
      if (values.Player4Email) {
        player4Data.Player4Email = values.Player4Email;
      }
      if (values.Player4Telephone) {
        player4Data.Player4Telephone = values.Player4Telephone;
      }
      setPlayer4Details(player4Data);
    }
  }, [])




  return (
    <div className="">
      <div className="grid grid-cols-1 py-4">
        <hr />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 py-4 lg:py-8">
        <div className="grid grid-cols-1 gap-6 lg:px-[40px]">
          <label className="block">
            <span className="text-black font-medium text-[17px] mb-2">
              PLAYER THREE DETAILS <i>(Optional)</i>
            </span>
            {/* isloggedin ? 
						<label className="block">
							<input type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" onChange={(e) => PlayerThreeMembershipCheck(e.target.checked)} />
							<span className="ml-4 font-normal text-[#939393]">Has membership</span>
						</label> : null */}
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              First Name
            </span>
            <input
              type="text"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player3Firstname"
              name="Player3Firstname"
              value={player3Details.Player3Firstname || ''}
              onChange={updatePlayer3Data}
              disabled={disableplayerthreeinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Last Name
            </span>
            <input
              type="text"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player3Lastname"
              name="Player3Lastname"
              value={player3Details.Player3Lastname || ''}
              onChange={updatePlayer3Data}
              disabled={disableplayerthreeinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Email address
            </span>
            <input
              type="email"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player3Email"
              name="Player3Email"
              value={player3Details.Player3Email || ''}
              onChange={updatePlayer3Data}
              disabled={disableplayerthreeinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Phone Number
            </span>
            <input
              type="tel"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player3Telephone"
              name="Player3Telephone"
              value={player3Details.Player3Telephone || ''}
              onChange={updatePlayer3Data}
              disabled={disableplayerthreeinput ? "disabled" : ""}
            />
          </label>
        </div>
        <div className="d-block md:hidden grid grid-cols-1 py-4">
          <hr />
        </div>
        <div className="grid grid-cols-1 gap-6 lg:px-[40px]">
          <label className="block">
            <span className="text-black font-medium text-[17px] mb-2">
              PLAYER FOUR DETAILS <i>(Optional)</i>
            </span>
            {/* isloggedin ? 
						<label className="block">
							<input type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" onChange={(e) => PlayerFourMembershipCheck(e.target.checked)} />
							<span className="ml-4 font-normal text-[#939393]">Has membership</span>
						</label> : null */}
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              First Name
            </span>
            <input
              type="text"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player4Firstname"
              name="Player4Firstname"
              value={player4Details.Player4Firstname || ''}
              onChange={updatePlayer4Data}
              disabled={disableplayerfourinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Last Name
            </span>
            <input
              type="text"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player4Lastname"
              name="Player4Lastname"
              value={player4Details.Player4Lastname || ''}
              onChange={updatePlayer4Data}
              disabled={disableplayerfourinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Email address
            </span>
            <input
              type="email"
              className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player4Email"
              name="Player4Email"
              value={player4Details.Player4Email || ''}
              onChange={updatePlayer4Data}
              disabled={disableplayerfourinput ? "disabled" : ""}
            />
          </label>
          <label className="block">
            <span className="text-[#939393] font-medium text-[18px]">
              Phone Number
            </span>
            <input
              type="tel"
              className="form-input mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
              id="Player4Telephone"
              name="Player4Telephone"
              value={player4Details.Player4Telephone || ''}
              onChange={updatePlayer4Data}
              disabled={disableplayerfourinput ? "disabled" : ""}
            />
          </label>
        </div>
      </div>
    </div>
  );
}
