import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token:'',
}

export const authSlice = createSlice({
    name:'user',
    initialState,
    reducers: {
        addToken:(state,action)=> {
            state.token= localStorage.getItem('token')
        },
        logoutUser:(state)=> {
            /* state.token= ''
            state.authenticated= false
            state.userData= {} */
        }
    }
})

export default authSlice.reducer