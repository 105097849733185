import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaUserCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import logo from "../Logo-e1584702777409.png";
import authActions from "../store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submenu, setSubmenu] = useState(false);
  const { logoutUser } = authActions;

  const showSubmenu = () => {
    if (submenu) {
      setSubmenu(false);
    } else {
      setSubmenu(true);
    }
  };

  const logout = () => {
    /* localStorage.removeItem("userData");
		localStorage.removeItem("token");
		localStorage.removeItem("userid"); */
    localStorage.clear();
    toast.success("Logout Successful", {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
      iconTheme: {
        primary: "#4BB543",
        secondary: "#FFF",
      },
    });
    //dispatch(logoutUser());
    navigate("/login");
  };

  return (
    <>
      <nav className="bg-white shadow-lg mb-10">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-center md:justify-between w-full md:w-auto">
            <div className="flex space-x-7">
              <div>
                <a href="/" className="flex items-center py-4 px-2">
                  <img src={logo} alt="Logo" className="h-12 w-15 mr-2" />
                  {/* <span className="font-semibold text-gray-500 text-lg">Navigation</span> */}
                </a>
              </div>
              {/* <div className="hidden md:flex items-center space-x-1">
							<a href="" className="py-4 px-2 text-green-500 border-b-4 border-green-500 font-semibold ">Home</a>
							<a href="" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Services</a>
							<a href="" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">About</a>
							<a href="" className="py-4 px-2 text-gray-500 font-semibold hover:text-green-500 transition duration-300">Contact Us</a>
						</div> */}
            </div>
            <div className="hidden md:flex items-center space-x-3 ">
              {!userData && (
                <>
                  <a
                    href="/login"
                    className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                  >
                    Log In
                  </a>
                </>
              )}

              {userData && (
                <div className="dropup relative select-none">
                  <a
                    className="no-underline cursor-pointer text-gray-500 hover:text-black flex flex-col"
                    onClick={showSubmenu}
                  >
                    <div>
                      <FaUserCircle style={{ display: "inline" }} size={30} />{" "}
                      {userData.FirstName ? userData.FirstName : "User"}
                      {submenu && (
                        <FaChevronUp
                          style={{ display: "inline", marginLeft: "5px" }}
                        />
                      )}
                      {!submenu && (
                        <FaChevronDown
                          style={{ display: "inline", marginLeft: "5px" }}
                        />
                      )}
                    </div>
                  </a>
                  {submenu && (
                    <div className="dropup-content shadow-lg">
                      <a
                        className="no-underline cursor-pointer text-gray-500 hover:text-black"
                        href="/MyProfile"
                      >
                        <div>My Profile</div>
                      </a>
                      <a
                        className="no-underline cursor-pointer text-gray-500 hover:text-black"
                        href="/changePassword"
                      >
                        <div>Change Password</div>
                      </a>
                      <a
                        className="no-underline cursor-pointer text-gray-500 hover:text-black"
                        href="/MyBookings"
                      >
                        <div>My Bookings</div>
                      </a>
                      <a
                        className="no-underline cursor-pointer text-gray-500 hover:text-black"
                        onClick={logout}
                      >
                        <div>Logout</div>
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="md:hidden flex items-center">
              <button className="outline-none mobile-menu-button">
                {/* <svg className=" w-6 h-6 text-gray-500 hover:text-green-500 "
							x-show="!showMenu"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path d="M4 6h16M4 12h16M4 18h16"></path>
						</svg> */}
              </button>
            </div>
          </div>
        </div>
        <div className="md:hidden mobile-menu">
          <ul className="text-center p-5">
            {!userData && (
              <li>
                <a
                  href="/login"
                  className="block text-center py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                >
                  Log In
                </a>
              </li>
            )}

            {userData && (
              <li className="dropup relative select-none">
                <a
                  className="no-underline cursor-pointer text-gray-500 hover:text-black flex flex-col"
                  onClick={showSubmenu}
                >
                  <div>
                    <FaUserCircle style={{ display: "inline" }} size={30} />{" "}
                    {userData.FirstName ? userData.FirstName : "User"}
                    {submenu && (
                      <FaChevronUp
                        style={{ display: "inline", marginLeft: "5px" }}
                      />
                    )}
                    {!submenu && (
                      <FaChevronDown
                        style={{ display: "inline", marginLeft: "5px" }}
                      />
                    )}
                  </div>
                </a>
                {submenu && (
                  <div className="dropup-content shadow-lg left-2/4 -translate-x-2/4">
                    <a
                      className="no-underline cursor-pointer text-gray-500 hover:text-black"
                      href="/MyProfile"
                    >
                      <div>My Profile</div>
                    </a>
                    <a
                      className="no-underline cursor-pointer text-gray-500 hover:text-black"
                      href="/changePassword"
                    >
                      <div>Change Password</div>
                    </a>
                    <a
                      className="no-underline cursor-pointer text-gray-500 hover:text-black"
                      href="/MyBookings"
                    >
                      <div>My Bookings</div>
                    </a>
                    <a
                      className="no-underline cursor-pointer text-gray-500 hover:text-black"
                      onClick={logout}
                    >
                      <div>Logout</div>
                    </a>
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
