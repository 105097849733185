import React, { useState, useEffect } from "react";
import PickDate from "../components/Pick_a_date";
import PersonalInfo from "../components/Personal_info";
import ReviewOrder from "../components/Review_order";
import Header from "../components/Header";
import CountdownTimer from "../components/CountdownTimer";

export default function Booking() {
  const [startTimerFlag, setStartTimerFlag] = useState(false);
  const [initialSeconds, setInitialSeconds] = useState(0);
  const [formstep, setFormStep] = useState(1);
  const [formdata, setFormData] = useState([]);
  const [classInProgress, setClassInProgress] = useState("");
  // Go back to prev step
  const prevStep = () => {
    console.log(
      "Back Clicked | Current Step : " + (formstep + 1),
      formdata
    );
    setFormStep(formstep - 1);
    ChangeStepCss(formstep - 1);
  };

  // Proceed to next step
  const nextStep = () => {
    if (formdata.expiry_secs > 0 && !startTimerFlag) {
      let secs = parseInt(formdata.expiry_secs);
      setInitialSeconds(secs);
      setStartTimerFlag(true);
    }
    console.log(
      "Next Clicked | Current Step : " + (formstep + 1), formdata
    );
    setFormStep(formstep + 1);
    ChangeStepCss(formstep + 1);
  };

  const updateReservationSecs = (expiry_secs) => {
    console.log('updateReservationSecs', expiry_secs);
    setInitialSeconds(expiry_secs);
    setStartTimerFlag(true);
  };

  const ChangeStepCss = (formstep) => {
    switch (formstep) {
      case 1:
        setClassInProgress("");
        break;
      case 2:
        setClassInProgress("personal_info");
        break;
      case 3:
        setClassInProgress("review_info");
        break;
    }
  };

  /*console.log(formstep);*/

  useEffect(() => {
    const u_b_id = localStorage.getItem('u_b_id');
    if (!u_b_id) {
      localStorage.setItem("u_b_id", Math.floor(Date.now() / 1000) + Math.random().toString(36).slice(2));
    }
  }, []);


  return (
    <>
      <Header />
      <div className="grid grid-cols-1 booking-nav-col">
        <div
          className={
            "flex md:pt-[40px] md:pb-[15px] booking-nav " + classInProgress
          }
        >
          <div className="w-4/12 md:w-1/6 place-items-center p-2">
            <p
              className={
                "md:text-[18px] text-[16px] font-medium text-center cursor-pointer " +
                (formstep >= 1 ? " text-[#17ba17]" : "")
              }
            >
              Pick Date &amp; Time
            </p>
          </div>
          <div className="w-4/12 md:w-1/6 place-items-center p-2">
            <p
              className={
                "md:text-[18px] text-[16px] font-medium text-center cursor-pointer " +
                (formstep >= 2 ? " text-[#17ba17]" : "")
              }
            >
              Personal Info
            </p>
          </div>
          <div className="w-4/12 md:w-1/6 place-items-center p-2">
            <p
              className={
                "md:text-[18px] text-[16px] font-medium text-center cursor-pointer " +
                (formstep >= 3 ? " text-[#17ba17]" : "")
              }
            >
              Payment Info
            </p>
          </div>
        </div>
      </div>
      {startTimerFlag && <CountdownTimer startTimerFlag={startTimerFlag} initialSeconds={initialSeconds} values={formdata} updateReservationSecs={updateReservationSecs}></CountdownTimer>}
      {renderSwitch(formstep, prevStep, nextStep, formdata, setFormData)}
    </>
  );
}

function renderSwitch(formstep, prevStep, nextStep, formdata, setFormData) {
  switch (formstep) {
    case 1:
      return (
        <PickDate
          nextStep={nextStep}
          values={formdata}
          setFormData={setFormData}
        />
      );
    case 2:
      return (
        <PersonalInfo
          nextStep={nextStep}
          prevStep={prevStep}
          values={formdata}
          setFormData={setFormData}
        />
      );
    case 3:
      return (
        <ReviewOrder
          prevStep={prevStep}
          values={formdata}
          setFormData={setFormData}
        />
      );
    default:
      console.log("Form cannot perform any action try after sometime.");
  }
}
