import React from 'react'
import { Link } from 'react-router-dom'
//import logo from '../Logo-e1584702777409.png'
import '../styles/css/Global-Style.css'
import '../styles/css/Personal_info.css'

export default function PersonalInfo() {
  return (
	<div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa]">
		<div className="w-full bg-white md:shadow-md md:border md:border-gray-200 rounded-lg">
			<div className="grid grid-cols-1 booking-nav-col">
				<div className="flex md:pt-[40px] md:pb-[15px] booking-nav">
					<div className="lg:w-1/6 place-items-center">
						<p className="md:text-[18px] text-16px font-medium text-center text-[#17ba17]">Select Type</p>
					</div>
					<div className="lg:w-1/6 place-items-center">
						<p className="md:text-[18px] text-16px font-medium text-center">Pick a Date</p>
					</div>
					<div className="lg:w-1/6 place-items-center">
						<p className="md:text-[18px] text-16px font-medium text-center">Pick a Time</p>
					</div>
					<div className="lg:w-1/6 place-items-center">
						<p className="md:text-[18px] text-16px font-medium text-center">Personal Info</p>
					</div>
					<div className="lg:w-1/6 place-items-center">
						<p className="md:text-[18px] text-16px font-medium text-center">Payment Info</p>
					</div>
				</div>
			</div>
			<div className="w-full flex flex-col px-[20px] py-[20px] lg:px-[40px] lg:py-[30px]">
				<div className="w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[40px] lg:py-[30px]">
					<h2 className="text-[22px] font-medium hidden lg:block text-[#8d8d8d]">Your Details</h2>
					<div className="grid grid-cols-1 lg:grid-cols-2 py-4 lg:py-8">
						<div className="grid grid-cols-1 gap-6 lg:px-[40px]">
							<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">First Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Last Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Email id</span>
								<input type="email" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Password</span>
								<input type="password" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Telephone</span>
								<input type="tel" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
						</div>
						<div className="grid grid-cols-1 gap-6 lg:px-[40px]">
	  						<label className="block pt-6 lg:pt-[50px]">
								<span className="text-[#8d8d8d] font-medium text-[17px]">PLAYER TWO DETAILS</span>
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">First Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Last Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Email id</span>
								<input type="email" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Phone Number</span>
								<input type="tel" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
						</div>
					</div>
	  				<div className="grid grid-cols-1 py-4">
						<hr />	
					</div>
	  				<div className="grid grid-cols-1 lg:grid-cols-2 py-4 lg:py-8">
						<div className="grid grid-cols-1 gap-6 lg:px-[40px]">
							<label className="block">
								<span className="text-[#8d8d8d] font-medium text-[17px]">PLAYER THREE DETAILS</span>
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">First Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Last Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Email id</span>
								<input type="email" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Phone Number</span>
								<input type="tel" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
						</div>
						<div className="grid grid-cols-1 gap-6 lg:px-[40px]">
	  						<label className="block">
								<span className="text-[#8d8d8d] font-medium text-[17px]">PLAYER FOUR DETAILS</span>
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">First Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Last Name</span>
								<input type="text" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Email id</span>
								<input type="email" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
	  						<label className="block">
								<span className="text-[#939393] font-medium text-[18px]">Phone Number</span>
								<input type="tel" className="form-input mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="" />
							</label>
						</div>
					</div>
	  				<div className="grid grid-cols-1 pb-4 lg:px-[40px]">
	  					<div className="grid pb-6">
							<label className="inline-flex items-center">
								<input type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" />
								<span className="ml-4 font-normal text-[#939393]">I have read and accept the Booking Terms and Conditions, especially with regard to refunds</span>
							</label>
							<label className="inline-flex items-center">
								<input type="checkbox" className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" />
								<span className="ml-4 font-normal text-[#939393]">I would like to receive Green Fee Offers and other golf promotions from Thorney Park Golf Club</span>
							</label>
	  					</div>
	  					<div className="grid pb-4">
	  						<h2 className="text-[#707070] font-semibold text-[18px] mb-4">BOOKING DETAILS</h2>
	  						<div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
								<div className="grid grid-cols-2 lg:grid-cols-4  ">
									<div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">TOTAL BOOKING FEE</div>
									<div className="pl-2 lg:pl-4 py-2 text-[#707070]">{'\u00A3'} 160.00</div>
								</div>
								<div className="grid grid-cols-2 lg:grid-cols-4 ">
									<div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">BOOKING TIME & DATE</div>
									<div className="pl-2 lg:pl-4 py-2 text-[#707070]">25TH DEC 2021, 05:19PM</div>
								</div>
								<div className="grid grid-cols-2 lg:grid-cols-4 ">
									<div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">COURSE</div>
									<div className="pl-2 lg:pl-4 py-2 text-[#707070]">1ST TEE</div>
								</div>
								<div className="grid grid-cols-2 lg:grid-cols-4 ">
									<div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">HOLES</div>
									<div className="pl-2 lg:pl-4 py-2 text-[#707070]">18</div>
								</div>
								<div className="grid grid-cols-2 lg:grid-cols-4 ">
									<div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">NUMBER OF PLAYERS</div>
									<div className="pl-2 lg:pl-4 py-2 text-[#707070]">3</div>
								</div>
	  							<div className="flex flex-col lg:flex-row justify-end pt-4 place-items-center">
	  								<Link to="/pick_a_date" className="w-full rounded bg-[#707070] text-[#ffffff] lg:px-[50px] py-2 lg:mx-2 text-center mb-4 lg:mb-0">Back</Link>
	  								<Link to="/review_order" className="w-full rounded bg-[#17BA17] text-[#ffffff] lg:px-[25px] py-2 lg:mx-2 text-center">Book Tea Time & Create Account</Link>
	  							</div>
	  						</div>
	  					</div>
					</div>
				</div>
			</div>
		</div>
	</div>

  )
}