import React from 'react'
//import { Link } from 'react-router-dom'
//import logo from '../Logo-e1584702777409.png'
import '../styles/css/Global-Style.css'
import '../styles/css/Create_account.css'

export default function CreateAccount() {
  return (
	<div>
		<button className="text-[18px] text-sm font-semibold text-[#707070] hover:underline mr-auto mb-6">&#9001; Back to login</button>
		<div className="h-screen flex items-center justify-center">
			<div className="bg-white md:shadow-md md:border md:border-gray-200 rounded-lg p-4 sm:p-6 lg:p-8 ">
				<form className="space-y-[1rem]">
					<div className="">
						<label for="firstname" className="text-sm font-medium text-gray-900 block mb-2">First Name</label>
						<input for="firstname" id="firstname" name="firstname" type="input" className="w-full bg-gray-300 border border-gray-300 rounded p-2.5" required=""/>
					</div>
					<div className="">
						<label for="lastname" className="text-sm font-medium text-gray-900 block mb-2">Last Name</label>
						<input for="lastname" id="lastname" name="lastname" type="input" className="w-full bg-gray-300 border border-gray-300 rounded p-2.5" required=""/>
					</div>
					<div className="">
						<label for="email" className="text-sm font-medium text-gray-900 block mb-2">Email</label>
						<input for="email" id="email" name="email" type="email" className="w-full bg-gray-300 border border-gray-300 rounded p-2.5" required=""/>
					</div>
					<div className="">
						<label for="password" className="text-sm font-medium text-gray-900 block mb-2">Password</label>
						<input for="password" id="password" name="password" type="password" className="w-full bg-gray-300 border border-gray-300 rounded p-2.5" required=""/>
					</div>
					<div className="">
						<label for="telephone" className="text-sm font-medium text-gray-900 block mb-2">Telephone</label>
						<input for="telephone" id="telephone" name="telephone" type="number" className="w-full bg-gray-300 border border-gray-300 rounded p-2.5" required=""/>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
						<input id="remember" aria-describedby="remember" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-[#17BA17]-300 h-4 w-4 rounded" required="" />
						</div>
						<div className="text-sm ml-3">
						<label for="remember" className="font-medium text-gray-900 dark:text-gray-300">By checking this box, I agree with the privacy policy, terms and conditions</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
						<input id="remember" aria-describedby="remember" type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-[#17BA17]-300 h-4 w-4 rounded" required="" />
						</div>
						<div className="text-sm ml-3">
						<label for="remember" className="font-medium text-gray-900 dark:text-gray-300">Please opt in to agree to the GDPR regulations</label>
						</div>
					</div>
					<button type="submit" className="w-full text-[#17BA17] outline border-[#17BA17]-300 font-medium rounded text-sm px-5 py-2.5 text-center uppercase">Create account</button>   
				</form>
			</div>
		</div>
	</div>
  )
}