import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/Header';
import usersService from '../services/usersService';
import toast from 'react-hot-toast';
import { FaSpinner } from "react-icons/fa"

export default function ChangePassword() {
	
	const user_name = JSON.parse(localStorage.getItem('userData')).email;
	const user_id = localStorage.getItem('userid');
	const [hidepwdblock, setHidePwdBlock] = useState(false);
	const [passwordDetails, setPasswordDetails] = useState()
	const [formSubmitted, setFormSubmitted] = useState(false);
    // CurrentPassword: Yup.string().required('The Current Password field is required'),
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('The New Password field is required'),
        ConfirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setError, formState, setValue  } = useForm(formOptions);
    const { errors } = formState;

	const updateProfileData = (e)=>{
        setPasswordDetails({...passwordDetails , [e.target.name]:e.target.value});
    }

	const saveProfileDetails = (user_data) => {
        //e.preventDefault();
        setFormSubmitted(true);
        console.log(user_data);
        
        usersService.updateUserDetails(user_id, user_data).then(response => { 
            console.log(response);
            toast.success('Password changed successfully',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
                iconTheme: {
                    primary: '#4BB543',
                    secondary: '#FFF',
                },
            });
            setFormSubmitted(false);
        }).catch(error => {
            console.log("Check User error : ", error);
        });
	};

	useEffect(() => {
        document.title = "Change Password - Thorney Park Golf Club";
		if((typeof user_id != "undefined")) { 
			
            usersService.getUserDetailsByUserId(user_id).then(response => { 
				if(response.data !== undefined) {
					setPasswordDetails(response.data);
					setHidePwdBlock(true);
				} 
			}).catch(error => {
				console.log("Check User error : ", error);
			});
		}
	
	  return () => {
		
	  }
	}, [])
	
	


	return (
		<>
        <Header />
		{passwordDetails && <>
                <div className="mt-10 sm:mt-0">  
                    <div className="m-6 mb-1 sm:px-0">
                        <div className="px-5 text-center">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Change Password</h3>
                            <p className="mt-1 text-sm text-gray-600">Password can be updated here.</p>
                        </div>
                    </div>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200"></div>
                        </div>
                    </div>
                    <div className="md:grid flex items-center justify-center">
                        <div className="mt-2 md:mt-0 md:col-span-2">
                        <form method="POST" onSubmit={handleSubmit(saveProfileDetails)}>
                            <div className="rounded-lg shadow-xl border overflow-hidden sm:rounded-md m-5 mt-0">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    
                                    {/* <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="CurrentPassword" className="block text-sm font-medium text-gray-700">
                                        Current Password *
                                        </label>
                                        <input
                                            type="text"
                                            name="CurrentPassword"
                                            id="CurrentPassword"
                                            autoComplete="password"  {...register('CurrentPassword')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.CurrentPassword?.message}</div>
                                    </div> */}

                                    <div className="col-span-12">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        New Password *
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password" {...register('password')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.password?.message}</div>
                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="ConfirmPassword" className="block text-sm font-medium text-gray-700">
                                        Confirm Password *
                                        </label>
                                        <input
                                            type="password"
                                            name="ConfirmPassword"
                                            id="ConfirmPassword"  {...register('ConfirmPassword')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.ConfirmPassword?.message}</div>
                                    </div>

                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                                {formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" disabled
                                ><FaSpinner icon="spinner" className="spinner" /> &nbsp;Processing</button> }

                                {!formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >Save</button> }

                                
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </>
            }
		</>
	);
}