import React,{useEffect} from 'react'
import { Routes , Route } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import SelectType from './pages/Select_your_type'
import Login from './pages/Login'
import PickDate from './pages/Pick_a_date'
import CreateAccount from './pages/Create_account'
import PersonalInfo from './pages/Personal_info'
import ReviewOrder from './pages/Review_order'
import Test from './pages/Test'
import Booking from './pages/Booking'
import Checkoutform from './pages/Checkout_form'
import Profile from './pages/Profile'
import MyBookings from './pages/MyBookings'
//import Stripemain from './stripe_payment/Stripe_main'
//import Charge from './stripe_payment/Charge';
import {  useNavigate,Navigate,useLocation,Outlet  } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import authActions from './store/actions/authAction'
import ChangePassword from './pages/ChangePassword'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'


export default function App() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { addToken } = authActions;
	const token = localStorage.getItem('token');
	if(token) {
		dispatch(addToken());
	}

	return (
		<>
			<Routes>
				<Route exact path="/" element={<SelectType/>} />
				<Route exact path="/booking" element={<Booking/>}  />
				<Route exact path="/forgotPassword" element={<ForgotPassword/>}  />
				<Route exact path="/resetPassword/:token" element={<ResetPassword/>}  />
				<Route element={<RequireAuth />}>
					<Route exact path="/login" element={<Login/>}  />
					<Route exact path="/create_account" element={<CreateAccount/>}  />
					<Route exact path="/pick_a_date" element={<PickDate/>} />
					<Route exact path="/Personal_info" element={<PersonalInfo/>}  />
					<Route exact path="/review_order" element={<ReviewOrder/>}  />
					<Route exact path="/test" element={<Test/>} />
					<Route exact path="/checkoutform" element={<Checkoutform/>}  />
					<Route exact path="/myProfile" element={<Profile />}  />
					<Route exact path="/changePassword" element={<ChangePassword />}  />
					<Route exact path="/myBookings" element={<MyBookings />}  />
				</Route>
			</Routes>
			<Toaster position="top-right" reverseOrder={false} />
		</>
	)
}


function RequireAuth() {
	const token = localStorage.getItem('token');
	let location = useLocation();

	const current_path = window.location.pathname.replaceAll('/','')    ;
	const publicPaths = ["login","register","booking"];
	if(token) {
		if(publicPaths.includes(current_path)) {
			return <Navigate to="/booking" state={{ from: location }} replace />;
		}
	} else {
		if(!publicPaths.includes(current_path)) {
			return <Navigate to="/login" state={{ from: location }} replace />;
		}
	}

  
	return <Outlet />;
  }