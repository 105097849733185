import axios from "axios";

class BookingsService {
  async getBookingDetailsByUserId(user_id) {
    const qs = require("qs");
    const query = qs.stringify(
      {
        populate: "*",
        filters: {
          users_permissions_user: {
            id: {
              $eq: user_id,
            },
          },
        },
        sort: ["BookingDate:desc"],
        publicationState: "preview",
      },
      {
        encodeprofileDetailsOnly: true,
      }
    );

    const response = axios.get(
      process.env.REACT_APP_API_URL + "tee-time-booking-details?" + query,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }

  async getDefaultMembership() {
    const qs = require("qs");
    const query = qs.stringify({
      filters: {
        IsDefault: true,
      },
    });

    const response = axios.get(
      process.env.REACT_APP_API_URL + "memberships/?" + query,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }

  /* async getBookingDetailsByUserId(user_id) {
        
        const response = axios.get(process.env.REACT_APP_API_URL + 'tee-time-booking-details/1', { 
            headers: { Authorization: process.env.REACT_APP_API_KEY } 
        })

        return response;
    } */

  /* async updateUserDetails(user_id, user_data) {
        const response = axios.put(process.env.REACT_APP_API_URL + 'users/' + user_id,user_data, { 
            headers: { Authorization: process.env.REACT_APP_API_KEY } 
        })

        return response;
    } */
}

export default new BookingsService();
