import axios from "axios";

class UsersService {
  async getUserDetailsByUsername(username) {
    const qs = require("qs");
    const query = qs.stringify(
      {
        populate: "MembershipID",
        filters: {
          email: {
            $eq: username,
          },
        },
      },
      {
        encodeprofileDetailsOnly: true,
      }
    );

    const response = axios.get(
      process.env.REACT_APP_API_URL + "users?" + query,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }

  async getUserDetailsByUserId(user_id) {
    const response = axios.get(
      process.env.REACT_APP_API_URL + "users/" + user_id,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }

  async updateUserDetails(user_id, user_data) {
    const response = axios.put(
      process.env.REACT_APP_ROOT_URL +
        "plugin::users-permissions.user/" +
        user_id,
      user_data,
      {
        headers: {
          Authorization: process.env.REACT_APP_ROOT_KEY,
        },
      }
    );

    return response;
  }

  async ForgotPassword(user_data) {
    const response = axios.post(
      process.env.REACT_APP_API_URL + "auth/forgot-password",
      user_data,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }

  async ResetPassword(user_data) {
    const response = axios.post(
      process.env.REACT_APP_API_URL + "auth/reset-password",
      user_data,
      {
        headers: { Authorization: process.env.REACT_APP_API_KEY },
      }
    );

    return response;
  }
}

export default new UsersService();
