import React from "react";

export default function Modal() {
  const redirectAfterComplete = () => {
    window.location.href = "/booking";
  };

  return (
    <>
      <div
        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center bg-[#000000ad]"
        id="modal-id"
      >
        <div className="relative w-auto my-6 mx-auto max-w-2xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="bg-white p-6  md:mx-auto">
              <svg
                viewBox="0 0 24 24"
                className="text-green-600 w-16 h-16 mx-auto my-6"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                ></path>
              </svg>
              <div className="text-center">
                <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                  Your Booking is Confirmed
                </h3>
                <p className="text-gray-600 my-2">
                  Thank You. We will send you a booking confirmation by email.
                </p>
                <div className="py-10 text-center">
                  <a
                    href="/booking"
                    className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
                  >
                    Return to home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="hidden opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>
    </>
  );
}
