import React, { useState } from 'react';
import moment from 'moment';
import TimeRangeSlider from 'react-time-range-slider';
import axios from 'axios';
import '../styles/css/Global-Style.css'
import '../styles/css/Pick_a_date.css'

export default function PickDate() {
	const [datevalue, setDateValue] = useState(new Date());
	const [timerangevalue, setTimeRangeValue] = useState({start: "10:00", end: "17:00"});
	const [starttimerangevalue, setStartTimeRangeValue] = useState("10:00");
	const [endtimerangevalue, setEndTimeRangeValue] = useState("17:00");
	const [errormsg, setErrorMsg] = useState("");

	const timeChangeHandler = (time) => {
		setTimeRangeValue(time);
		setStartTimeRangeValue(time.start);
		setEndTimeRangeValue(time.end);
		getTimeSlotData();
		console.log(timerangevalue, starttimerangevalue, endtimerangevalue);
	}

	const dateChangeHandler = (dateval) => {
		//console.log(dateval);
		setDateValue(dateval);
		setTimeout(function() {
			getTimeSlotData();
		}, 500);
	}

	function getTimeSlotData() {
		const qs = require('qs');
		const query = qs.stringify({
			filters: {
				Date: {
					$eq: document.getElementById("pick_date").value,
				},	
				StartTime: {
					$gte: starttimerangevalue+":00.000",
				},
				EndTime: {
					$lte: endtimerangevalue+":00.000",
				},
			},
		}, {
			encodeValuesOnly: true,
		});
		//console.log(query);
		const AuthStr = 'Bearer b48472d55ad29d93254ec47ff63f8df42b0a83def74fe293261520248df7e6f11ecb01832c634f81b77d93ca5a35a7c05dc22f0a842a8df7a3ae76074efd01d18876085047ebdcb5ea31a3697fd0d41c94715b1b81c7920f96bf3e180b885692138a0320ecfa793164f0b108d4c0a3be9421c1383c73760eb78ffc9553d1e5d7'; 

		axios.get('http://server176.geeconglobal.com:1337/api/tee-time-slots?'+query, { 
			headers: { Authorization: AuthStr } 
		}).then(response => {
			const datas = response.data.data;
			//console.log(datas);
			var timeslotdata = ""
			datas.forEach((data) => (  
				//console.log(data),
				timeslotdata += "<div class='w-full border border-[#17BA17] text-[#17BA17] lg:text-[20px] sm:text-[12px] font-medium text-center lg:px-4 sm:px-2 py-2 rounded hover:bg-[#17BA17] hover:text-[#ffffff]'>" + moment(data.attributes.StartTime, "HH:mm:ss.SSS").format("HH:mm") + "</div>"
            ))
			document.getElementById("intial_timming").innerHTML = '';
			if(timeslotdata != null) {
				document.getElementById("timming_slot").innerHTML = timeslotdata;
			} else {
				document.getElementById("timming_slot").innerHTML = '';
			}
		}).catch(error => {
			console.log("An error has Occured ",error);
			setErrorMsg("Some error has occured.");
		});
	}

	return (
		<div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa]">
		  <div className="bg-white rounded shadow-xl">
			<div className="grid grid-cols-1 booking-nav-col">
			  <div className="flex md:pt-[40px] md:pb-[15px] booking-nav">
				  <div className="lg:w-1/6 place-items-center">
					<p className="md:text-[18px] text-[16px] font-medium text-center text-[#17ba17]">Select Type</p>
				  </div>
				  <div className="lg:w-1/6 place-items-center">
					<p className="md:text-[18px] text-[16px] font-medium text-center">Pick a Date</p>
				  </div>
				  <div className="lg:w-1/6 place-items-center">
					<p className="md:text-[18px] text-[16px] font-medium text-center">Pick a Time</p>
				  </div>
				  <div className="lg:w-1/6 place-items-center">
					<p className="md:text-[18px] text-[16px] font-medium text-center">Personal Info</p>
				  </div>
				  <div className="lg:w-1/6 place-items-center">
					<p className="md:text-[18px] text-[16px] font-medium text-center">Payment Info</p>
				  </div>
			  </div>
			</div>
			<div className="w-full flex flex-col">
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 px-[20px] lg:px-[50px] py-[20px] lg:py-[30px]">
					<div className="h-screen w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[40px] lg:py-[50px]">
						<div className="grid grid-cols-1 gap-6">
							<label className="block">
								<span className="text-[#707070] font-medium text-[18px]">Select a date</span>
								<input type="date" className="mt-1 block w-full rounded-md bg-[#ebebeb] border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" id="pick_date" value={datevalue} onChange={(e) => dateChangeHandler(e.target.value)} /> 
							</label>
							<label className="block">
								<span className="text-[#707070] font-medium text-[18px]">Time Range</span>
								<div>
									<TimeRangeSlider
										disabled={false}
										format={24}
										maxValue={"23:59"}
										minValue={"00:00"}
										name={"time_range"}
										onChange={timeChangeHandler}
										step={10}
										value={timerangevalue}/>
								</div>
							</label>
							<label className="block">
								<button type="button" className="bg-[#EBEBEB] rounded-md py-2 lg:px-[25px] text-[#4E4E4E] text-[18px] font-medium">
									<span id="start_time">{starttimerangevalue}</span> to  <span id="end_time">{endtimerangevalue}</span>
								</button>
							</label>
							<label className="block">
								<p className="font-[14px] pt-[5px] text-[#ff0000] text-center">{errormsg}</p>
							</label>
						</div>
					</div>
					<div className="w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[20px] lg:py-[30px] place-items-center">
						<h2 className="text-[24px] text-[#4E4E4E] font-semibold text-center mb-3">TEE TIMES FROM {'\u00A3'} 27.00</h2>
						<div className="grid grid-cols-5 gap-2 place-items-center" id="timming_slot">
							
						</div>
						<div className="grid grid-cols-1 place-items-center" id="intial_timming">
							<p className="text-[18px] font-medium text-center "> Please select date first to view Tee Time.</p>
						</div>
					</div>
				</div>          
			</div>
		  </div>
		</div>
	)
}