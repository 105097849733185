import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Player1form from "./Player1_form";
import Player2form from "./Player2_form";
import Player3form from "./Player3_form";
import Player4form from "./Player4_form";
import "../styles/css/Global-Style.css";
import "../styles/css/Personal_info.css";

export default function PersonalInfo(props) {
  const { nextStep, prevStep, values, setFormData } = props;
  const [errors, setErrors] = useState({});
  const token = localStorage.getItem("token");
  let userData = localStorage.getItem("userData");
  if (userData) {
    userData = JSON.parse(userData);
  }

  const backToDate = () => {
    prevStep();
  };

  const continueReview = () => {
    nextStep();
  };

  const playercount = parseInt(values.Player_Count);

  var classnameinprogress = "personal_info";
  var selectedslotdate = "";
  if (typeof values.date !== "undefined") {
    selectedslotdate = moment(values.date, "yyyy-MM-DD").format("DD-MM-YYYY");
  }

  const checkValidation = () => {
    let formValid = true;
    let f_errors = {
      Player1Firstname: {},
      Player1Lastname: {},
      Player1Email: {},
      Player1Telephone: {},
      Player1Password: {},
    };

    if (
      !values.hasOwnProperty("Player1Firstname") ||
      values.Player1Firstname.length == 0
    ) {
      f_errors.Player1Firstname.message = "First Name is required";
      formValid = false;
    }
    if (
      !values.hasOwnProperty("Player1Lastname") ||
      values.Player1Lastname.length == 0
    ) {
      f_errors.Player1Lastname.message = "Last Name is required";
      formValid = false;
    }
    if (
      !values.hasOwnProperty("Player1Email") ||
      values.Player1Email.length == 0
    ) {
      f_errors.Player1Email.message = "Email is required";
      formValid = false;
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(values.Player1Email)) {
        formValid = false;
        f_errors.Player1Email.message = "Please enter correct email address";
      }
    }
    if (
      !values.hasOwnProperty("Player1Telephone") ||
      values.Player1Telephone.length == 0
    ) {
      f_errors.Player1Telephone.message = "Phone Number is required";
      formValid = false;
    } else {
      if (values.Player1Telephone.length > 15) {
        f_errors.Player1Telephone.message =
          "Phone Number should be 15 digits maximum";
        formValid = false;
      }
    }
    if (token == null || userData == null || token == "" || userData == "") {
      if (
        !values.hasOwnProperty("Player1Password") ||
        values.Player1Password.length == 0
      ) {
        f_errors.Player1Password.message = "Password is required";
        formValid = false;
      }
    }

    if (formValid) {
      console.log("All Validations passed");
      nextStep();
    } else {
      setErrors(f_errors);
      console.error("All Validations failed");

      return false;
    }
  };

  /* const setPlayer1Data = (playerInfo) => {
    console.log("Received Data", playerInfo);
    if (
      playerInfo.hasOwnProperty("Player1Firstname") &&
      playerInfo.Player1Firstname.length > 0
    ) {
      values.Player1Firstname = playerInfo.Player1Firstname;
    }
    if (
      playerInfo.hasOwnProperty("Player1Lastname") &&
      playerInfo.Player1Lastname.length > 0
    ) {
      values.Player1Lastname = playerInfo.Player1Lastname;
    }
    if (
      playerInfo.hasOwnProperty("Player1Email") &&
      playerInfo.Player1Email.length > 0
    ) {
      values.Player1Email = playerInfo.Player1Email;
    }
    if (
      playerInfo.hasOwnProperty("Player1Telephone") &&
      playerInfo.Player1Telephone.length > 0
    ) {
      values.Player1Telephone = playerInfo.Player1Telephone;
    }
    if (
      playerInfo.hasOwnProperty("Player1Password") &&
      playerInfo.Player1Password.length > 0
    ) {
      values.Player1Password = playerInfo.Player1Password;
    }
  }; */

  return (
    <div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa]">
      <div className="w-full bg-white md:shadow-md md:border md:border-gray-200 rounded-lg">
        <div className="w-full flex flex-col px-[20px] py-[20px] lg:px-[40px] lg:py-[30px]">
          <div className="w-full bg-white shadow-md border border-gray-200 rounded-lg px-[20px] py-[10px] lg:px-[40px] lg:py-[30px]">
            <h2 className="text-[22px] font-medium hidden lg:block">
              Your Details
            </h2>
            {playercount === 1 ? (
              <Player1form values={values} errors={errors} />
            ) : null}
            {playercount >= 2 ? (
              <Player2form values={values} errors={errors} />
            ) : null}
            {playercount === 3 ? <Player3form values={values} /> : null}
            {playercount === 4 ? <Player4form values={values} /> : null}

            <div className="grid grid-cols-1 pb-4 lg:px-[40px]">
              <div className="grid pb-4">
                <h2 className="text-[#707070] font-semibold text-[18px] mb-4">
                  BOOKING DETAILS
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-2">
                  <div className="grid grid-cols-2 lg:grid-cols-4  ">
                    <div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">
                      TOTAL BOOKING FEE
                    </div>
                    <div className="pl-2 lg:pl-4 py-2 text-[#707070]">
                      {"\u00A3"}
                      {values.Player_Rate}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-4 ">
                    <div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">
                      BOOKING TIME & DATE
                    </div>
                    <div className="pl-2 lg:pl-4 py-2 text-[#707070]">
                      {values.selectedslot} {selectedslotdate}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-4 hidden">
                    <div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">
                      COURSE
                    </div>
                    <div className="pl-2 lg:pl-4 py-2 text-[#707070]">
                      1ST TEE
                    </div>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-4 hidden">
                    <div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">
                      HOLES
                    </div>
                    <div className="pl-2 lg:pl-4 py-2 text-[#707070]">18</div>
                  </div>
                  <div className="grid grid-cols-2 lg:grid-cols-4 ">
                    <div className="pl-2 lg:pl-4 lg:pr-8 py-2 bg-[#00000029] text-[16px] text-[#707070] rounded">
                      NUMBER OF PLAYERS
                    </div>
                    <div className="pl-2 lg:pl-4 py-2 text-[#707070]">
                      {values.Player_Count}
                    </div>
                  </div>
                  <div className="lg:flex items-center justify-end text-center p-4">
                    <button
                      className="text-[14pt] lg:text-[18px] font-medium bg-[#707070] text-white  px-5 py-2.5 rounded lg:w-[310px] w-full lg:mx-2 my-2 lg:my-0"
                      onClick={prevStep}
                    >
                      Back
                    </button>
                    <button
                      className="text-[14pt] lg:text-[18px] font-medium bg-[#17BA17] text-white px-5 py-2.5 rounded lg:w-[310px] w-full lg:mx-2 my-2 lg:my-0"
                      onClick={() => checkValidation()}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
