import React from 'react'
//import { Link } from 'react-router-dom'
//import logo from '../Logo-e1584702777409.png'
import '../styles/css/Global-Style.css'
import '../styles/css/Review_order.css'

export default function ReviewOrder() {
  return (
	<div className="main div">
		<p className="text-[16pt] lg:text-[30px] text-[#4E4E4E] font-semibold px-6">Confirmation Order</p>
		<div className="flex items-center justify-center">
			<div className="bg-white lg:shadow-lg lg:border lg:border-gray-200 rounded-[1rem] lg:m-8 p-4 w-full">
				<div className="w-full lg:flex justify-center lg:divide-x divide-gray-200">
					<div className="left-card w-full lg:px-4">
						<div className="info-div">
							<div className="flex items-start mb-4">
								<p className="text-[16pt] lg:text-[24px] text-[#707070] font-semibold">Your information</p>
								<a href="{#}" className="text-[16pt] lg:text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500">EDIT</a>
							</div>
							<div className="bg-gray-200 rounded-md block w-full p-8">
								<div className="">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
									<p className="text-[#707070] font-semibold   mb-2 text-[16pt] lg:text-[24px]">Sanjeev</p>
								</div>
								<div className="">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px] mb-2">Last Name</p>
									<p className="text-[#707070] font-semibold   mb-2 text-[16pt] lg:text-[24px]">Mishra</p>
								</div>
								<div className="">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px] mb-2">Email</p>
									<p className="text-[#707070] font-semibold   mb-2 text-[16pt] lg:text-[24px]">sanjeevmishra@gmail.com</p>
								</div>
								<div className="">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px] mb-2">Telephone</p>
									<p className="text-[#707070] font-semibold   mb-2 text-[16pt] lg:text-[24px]">+41 1234567890</p>
								</div>
								<div className="">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px] mb-2">Password</p>
									<p className="text-[#707070] font-semibold   mb-2 text-[16pt] lg:text-[24px]">*********</p>
								</div>
							</div>
						</div>
						<div className="booking-div py-6 ">
							<p className="text-[#707070] font-semibold py-4 text-[16pt] lg:text-[24px]">Booking Details</p>
							<div className="block w-full lg:shadow-none shadow-md lg:border-none border lg:rounded-none rounded-lg lg:p-0 p-4">
								<div className="flex lg:items-center justify-start pb-1">
									<p className="bg-gray-200 text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] rounded-none px-1.5 py-1 w-full lg:w-[270px]">TOTAL BOOKING FEE</p>
									<p className="text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] px-8 py-1 w-full lg:w-[270px]">$116.00</p>
								</div>
								<div className="flex lg:items-center justify-start pb-1">
									<p className="bg-gray-200 text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] rounded-none px-1.5 py-1 w-full lg:w-[270px]">COURSE</p>
									<p className="text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] px-8 py-1 w-full lg:w-[270px]">1st TEE</p>
								</div>
								<div className="flex lg:items-center justify-start pb-1">
									<p className="bg-gray-200 text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] rounded-none px-1.5 py-1 w-full lg:w-[270px]">HOLES</p>
									<p className="text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] px-8 py-1 w-full lg:w-[270px]">18</p>
								</div>
								<div className="flex lg:items-center justify-start pb-1">
									<p className="bg-gray-200 text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] rounded-none px-1.5 py-1 w-full lg:w-[270px]">NUMBER OF PLAYERS</p>
									<p className="text-left text-[#707070] font-medium text-[16pt] lg:text-[20px] px-8 py-1 w-full lg:w-[270px]">4</p>
								</div>

							</div>
						</div>
					</div>
					<div className="right-card w-full lg:px-4">
						<div className="player-div mb-4">
							<div className="flex items-start mb-4">
								<div className="">
									<p for="remember" className="font-semibold text-[#707070] text-[16pt] lg:text-[24px]">Player One Information</p>
								</div>
								<a href="{#}" className="text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500">EDIT</a>
							</div>
							<div className="bg-gray-200 rounded-md block w-full p-8 flex flex-wrap items-center justify-between">
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Sanjeev</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Last Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Mishra</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Email</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">sanjeevmishra@gmail.com</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Telephone</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">+41 1234567890</p>
								</div>
							</div>
						</div>
						<div className="player-div mb-4">
							<div className="flex items-start mb-4">
								<div className="">
									<p for="remember" className="font-semibold text-[#707070] text-[16pt] lg:text-[24px]">Player One Information</p>
								</div>
								<a href="{#}" className="text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500">EDIT</a>
							</div>
							<div className="bg-gray-200 rounded-md block w-full p-8 flex flex-wrap items-center justify-between">
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Sanjeev</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Last Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Mishra</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Email</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">sanjeevmishra@gmail.com</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Telephone</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">+41 1234567890</p>
								</div>
							</div>
						</div>
						<div className="player-div mb-4">
							<div className="flex items-start mb-4">
								<div className="">
									<p for="remember" className="font-semibold text-[#707070] text-[16pt] lg:text-[24px]">Player One Information</p>
								</div>
								<a href="{#}" className="text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500">EDIT</a>
							</div>
							<div className="bg-gray-200 rounded-md block w-full p-8 flex flex-wrap items-center justify-between">
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Sanjeev</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Last Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Mishra</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Email</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">sanjeevmishra@gmail.com</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Telephone</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">+41 1234567890</p>
								</div>
							</div>
						</div>
						<div className="player-div mb-4">
							<div className="flex items-start mb-4">
								<div className="">
									<p for="remember" className="font-semibold text-[#707070] text-[16pt] lg:text-[24px]">Player One Information</p>
								</div>
								<a href="{#}" className="text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500">EDIT</a>
							</div>
							<div className="bg-gray-200 rounded-md block w-full p-8 flex flex-wrap items-center justify-between">
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Sanjeev</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Last Name</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">Mishra</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Email</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">sanjeevmishra@gmail.com</p>
								</div>
								<div className="px-2 py-2">
									<p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Telephone</p>
									<p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">+41 1234567890</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="lg:flex items-center justify-end text-center p-4">
			<button className="text-[14pt] lg:text-[18px] font-medium bg-[#707070] text-white  px-5 py-2.5 rounded lg:w-[280px] w-full lg:mx-2 my-2 lg:my-0">Back</button>
			<button className="text-[14pt] lg:text-[18px] font-medium bg-[#17BA17] text-white px-5 py-2.5 rounded lg:w-[280px] w-full lg:mx-2 my-2 lg:my-0">Confirm Order</button>
		</div>
	</div>
  )
}
