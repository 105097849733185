import React from 'react'
import StripeCheckout from 'react-stripe-checkout';
//import { stripe } from "stripe";

//const stripe = require('../../node_modules/stripe')('sk_test_51KFKVvE3klJBDbXG84Z8jP3yufyCyjPmiysUCPGBvNcuLRyXAUpZjKd5KYlg5HPvE0c3dM7zTTLpowSi3gc6oUQD00D7xA6hLL');



export default function Checkoutform() {
	const priceForStripe = 5 * 100;
    const publishableKey = 'pk_test_51KFKVvE3klJBDbXGuwILG1CeFWpNxeiIY0uxsUqLyygQfsw7607RDS8vidaKBW4iUxWf4ooLqgk1Va80jyUMinHL00L25zgSOt';
	const secretKey = 'sk_test_51KFKVvE3klJBDbXG84Z8jP3yufyCyjPmiysUCPGBvNcuLRyXAUpZjKd5KYlg5HPvE0c3dM7zTTLpowSi3gc6oUQD00D7xA6hLL';
	
	/*
	const appearance = {
		theme: 'stripe',
	};

	const options = {
		clientSecret,
		appearance,
	};
	*/

    const onToken = token => {
        console.log(token, token.id);
        //alert('Payment Succesful!');
		/*
		const charge = stripe.charges.create({
		  amount: priceForStripe,
		  currency: 'usd',
		  description: 'Example charge',
		  source: token,
		  metadata: {order_id: '6735'},
		});
		
		console.log(charge);
		*/

    };

	
	return (
		<StripeCheckout
			className="text-[14pt] lg:text-[18px] font-medium bg-[#17BA17] text-white px-5 py-2.5 rounded lg:w-[280px] w-full lg:mx-2 my-2 lg:my-0"
            label='Pay Now'
            name='Freaky Jolly Co.'
            image='https://www.freakyjolly.com/wp-content/uploads/2020/04/fj-logo.png'
            description={`Your total is $${priceForStripe}`}
            amount={priceForStripe}
            panelLabel='Pay Now'
            token={onToken}
            stripeKey={publishableKey}
        />
	);

}
