import React, { useState } from 'react'
import axios from 'axios';
import moment from  'moment';

export default function Test() {
	function createBookingDetails(userid) {
		const AuthStr2 = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQyODQ1MzAwLCJleHAiOjE2NDU0MzczMDB9.KP2RTop95wtxJMYeNNTYsJpRjUicfnsp-qTMvJF-4t8';
		
		// request {"tee_time_slot":10,"users_permissions_user":8,"PaymentTransactionID":1,"BookingDate":"2022-01-09T18:30:00.000Z","Status":true,publishedAt: todaydate} 

		var bookingdetailsraw = {
			tee_time_slot: parseInt(308),
			users_permissions_user: parseInt(2),
			PaymentTransactionID: 1,
			BookingDate: moment().format('yyyy-MM-DD[T]HH:mm:ss.SSS[Z]'),
			Status: true			
		};
		
		axios.post('http://admin.teebooking.mydevsystems.com/content-manager/collection-types/api::tee-time-booking-detail.tee-time-booking-detail', bookingdetailsraw, { 
			headers: { Authorization: AuthStr2 }
		}).then(response => {
			console.log("Booking details response : ", response.data.id);
			var bookingresponse  = response;
			/*
			const bookingid = "";
			if(typeof bookingresponse.data.id !== "undefined") {
				bookingid = bookingresponse.data.id;
			} else {
				bookingid = bookingresponse.id;
			}
			setBookingId(bookingid);
			
			setShowModal(true);
			//window.location.href = "/";
			*/
		}).catch(error => {
			console.log("Booking details error : ", error.response.data);
			//setFormSubmitError("Issue with submitting form.");
			//setBookingId("");
		});
	}

	createBookingDetails(2);



	return (
		<div className="">
			<div className="">Hello I am testing Strapi API</div>
		</div>
	)
}

