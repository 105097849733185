import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../Logo-e1584702777409.png'
import '../styles/css/Global-Style.css'
import '../styles/css/Booking.css'

export default function SelectType() {

  return (
    <div className="w-full px-[10px] md:px-[50px] py-4 bg-[#fafafa]">
      {/* <div className="bg-white rounded shadow-xl"> */}
        {/* <div className="grid grid-cols-1 booking-nav-col">
          <div className="flex md:pt-[40px] md:pb-[15px] booking-nav">
              <div className="lg:w-1/6 place-items-center">
                <p className="md:text-[18px] text-16px font-medium text-center text-[#17ba17]">Select Type</p>
              </div>
              <div className="lg:w-1/6 place-items-center">
                <p className="md:text-[18px] text-16px font-medium text-center">Pick a Date</p>
              </div>
              <div className="lg:w-1/6 place-items-center">
                <p className="md:text-[18px] text-16px font-medium text-center">Pick a Time</p>
              </div>
              <div className="lg:w-1/6 place-items-center">
                <p className="md:text-[18px] text-16px font-medium text-center">Personal Info</p>
              </div>
              <div className="lg:w-1/6 place-items-center">
                <p className="md:text-[18px] text-16px font-medium text-center">Payment Info</p>
              </div>
          </div>
        </div> */}
        <div className="h-screen w-full flex flex-col justify-center items-center">
          <div className="bg-gray-800 shadow-xl border border-gray-200 rounded-[20px] lg:w-2/5 w-[90%] overflow-hidden mx-auto">
            <div className="py-4 px-8 mt-3 place-items-center">
              <img className="pb-4 mx-auto" src={logo} alt="Website_Logo" />
              <h4 className="pb-4 text-center text-white">Please select</h4>
              <div className="flex flex-col lg:px-8 place-items-center">
                <Link className="w-full inline-block text-center rounded-full bg-[#17ba17] text-white hover:bg-[#ffffff] hover:text-[#17ba17] font-medium py-[10px] lg:py-[5px] mb-4 text-[18px] " to="/booking">VISITOR</Link>
                <Link className="w-full inline-block text-center rounded-full bg-[#ffffff] text-[#17ba17] hover:bg-[#17ba17] hover:text-white  font-medium py-[10px] lg:py-[5px] mb-4 text-[18px]" to="/login">MEMBER</Link>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  )
}