import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../styles/css/Checkout.css";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const { triggerAPI, closeModal, isProcessingApi } = props;

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    await stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
      })
      .then(function (result) {
        console.log(result);
        if (result.hasOwnProperty("error")) {
          if (
            result.error.type === "card_error" ||
            result.error.type === "validation_error"
          ) {
            setMessage(result.error.message);
          } else {
            setMessage("An unexpected error occured. Please try again later");
          }
          setIsLoading(false);
        } else {
          triggerAPI(result.paymentIntent.id);
        }

        if (!isProcessingApi) {
          setIsLoading(false);
        }
      });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    /* if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false); */
  };

  return (
    <form id="payment-form" className="checkoutForm" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      <button
        className="mt-2"
        disabled={isLoading}
        id="cancel"
        onClick={() => closeModal()}
      >
        <span id="button-text">Cancel</span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
