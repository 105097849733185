import React from 'react'

export default function Playerreviewcard(props) {
  const { backToPersonal, values, playerNumber } = props;
  console.log(values);
  var PlayerNumInWord = "";
  var PlayerFirstName = "";
  var PlayerLastName = "";
  var PlayerEmail = "";
  var PlayerTelephone = "";
  var PlayerHasMembership = "";
  if (parseInt(playerNumber) == 2) {
    PlayerNumInWord = "Two";
    PlayerFirstName = values.Player2Firstname;
    PlayerLastName = values.Player2Lastname;
    PlayerEmail = values.Player2Email;
    PlayerTelephone = values.Player2Telephone;
    PlayerHasMembership = values.playertwohasmembership;
  } else if (parseInt(playerNumber) == 3) {
    PlayerNumInWord = "Three";
    PlayerFirstName = values.Player3Firstname;
    PlayerLastName = values.Player3Lastname;
    PlayerEmail = values.Player3Email;
    PlayerTelephone = values.Player3Telephone;
    PlayerHasMembership = values.playerthreehasmembership;
  } else if (parseInt(playerNumber) == 4) {
    PlayerNumInWord = "Four";
    PlayerFirstName = values.Player4Firstname;
    PlayerLastName = values.Player4Lastname;
    PlayerEmail = values.Player4Email;
    PlayerTelephone = values.Player4Telephone;
    PlayerHasMembership = values.playerfourhasmembership;
  }
  //console.log("PlayerFirstName", PlayerFirstName);

  return (
    <div className="player-div mb-4">
      <div className="flex items-start mb-4">
        <div className="">
          <p className="font-semibold text-[#707070] text-[16pt] lg:text-[24px]">Player {PlayerNumInWord} Information</p>
        </div>
        <button className="text-[20px] text-[#0044FF] hover:underline ml-auto dark:text-blue-500" onClick={backToPersonal}>EDIT</button>
      </div>
      {PlayerHasMembership ?
        <div className="grid grid-cols-1 py-4 lg:py-8 px-[10px] lg:px-[40px] bg-gray-200 rounded-md block w-full">
          <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px]">Already has Membership</p>
        </div>
        : (PlayerFirstName == undefined || PlayerFirstName == "") ? <div className="grid grid-cols-1 py-4 lg:py-8 px-[10px] lg:px-[40px] bg-gray-200 rounded-md block w-full">
          <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px]">No Details</p>
        </div> :
          <div className="grid grid-cols-1 py-4 lg:py-8 bg-gray-200 rounded-md block w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 px-[10px] lg:px-[40px]">
              <label className="block">
                <p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">First Name</p>
                <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">{PlayerFirstName}</p>
              </label>
              <label className="block">
                <p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Last Name</p>
                <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">{PlayerLastName}</p>
              </label>
            </div>
            <div className="grid grid-cols-1 gap-6 px-[10px] lg:px-[40px]">
              <label className="block">
                <p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Email</p>
                <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">{PlayerEmail}</p>
              </label>
              <label className="block">
                <p className="text-[#707070] font-medium text-[14pt] lg:text-[18px]">Telephone</p>
                <p className="text-[#707070] font-semibold text-[16pt] lg:text-[24px] ">{PlayerTelephone}</p>
              </label>
            </div>
          </div>
      }
    </div>
  )
}
