import React, { useState, useEffect } from 'react'
import bookingsService from '../services/bookingsService';
import Header from '../components/Header';
import moment from 'moment';
import { FaClock } from 'react-icons/fa';

const MyBookings = () => {
    const user_id = localStorage.getItem('userid');
    const [bookingDetails, setBookingDetails] = useState([])


    useEffect(() => {
        document.title = "My Bookings - Thorney Park Golf Club";
        if ((typeof user_id != "undefined")) {

            bookingsService.getBookingDetailsByUserId(user_id).then(response => {
                if (response.data !== undefined) {
                    console.log(response.data.data);
                    setBookingDetails(response.data.data);
                }
            }).catch(error => {
                console.log("Check User error : ", error);
            });
        }

        return () => {

        }
    }, [])

    return (
        <>
            <Header />
            <div className="mt-10 sm:mt-0">
                <div className="m-6 mb-1 sm:px-0">
                    <div className="px-5">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">My Bookings</h3>
                    </div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200"></div>
                    </div>
                </div>
                {bookingDetails.length === 0 && <div className='mt-20 text-center'><h1 className='text-2xl font-bold text-gray-500'>No Bookings found</h1></div>}
                {bookingDetails.length != 0 &&
                    <div className="border shadow-md rounded-lg mx-4 my-4 overflow-x-auto">
                        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                            Date
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                            Tee Time
                                        </th>
                                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                            No. Of Players
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bookingDetails.map((single_booking, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">{moment(single_booking.attributes.tee_time_slot.data.attributes.Date).format('DD MMM YYYY')}</p>
                                                </td>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        <FaClock className='inline mr-2' />
                                                        {moment(single_booking.attributes.tee_time_slot.data.attributes.Date + ' ' + single_booking.attributes.tee_time_slot.data.attributes.StartTime).format('hh:mm a')}
                                                    </p>
                                                </td>
                                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                    <p className="text-gray-900 whitespace-no-wrap">
                                                        {single_booking.attributes.tee_time_slot.data.attributes.PlayersBooked}
                                                    </p>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </>

    )
}

export default MyBookings
