import React, { useState, useEffect } from 'react'
import {  useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/Header';
import usersService from '../services/usersService';
import toast from 'react-hot-toast';
import { FaSpinner } from "react-icons/fa"

export default function ResetPassword() {

     let params = useParams();
    const passwordResetToken = params.token;
    console.log(passwordResetToken);
	const [passwordDetails, setPasswordDetails] = useState()
	const [formSubmitted, setFormSubmitted] = useState(false);
    // CurrentPassword: Yup.string().required('The Current Password field is required'),
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('The New Password field is required'),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setError, formState, setValue  } = useForm(formOptions);
    const { errors } = formState;

	const updateProfileData = (e)=>{
        setPasswordDetails({...passwordDetails , [e.target.name]:e.target.value});
    }

	const submitResetPassword = (user_data) => {
        //e.preventDefault();
        setFormSubmitted(true);
        console.log(user_data);
        user_data.code = passwordResetToken;
        usersService.ResetPassword( user_data).then(response => { 
            console.log(response);
            toast.success('Password has been reset successfully',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
                iconTheme: {
                    primary: '#4BB543',
                    secondary: '#FFF',
                },
            });
            setFormSubmitted(false);
        }).catch(error => {
            console.log("Check User error : ", error);
        });
	};

	useEffect(() => {
        document.title = "Reset Password - Thorney Park Golf Club";
	
	  return () => {
		
	  }
	}, [])
	
	


	return (
		<>
        <Header />
                <div className="mt-10 sm:mt-0">  
                    <div className="m-6 mb-1 sm:px-0">
                        <div className="px-5 text-center">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Reset Password</h3>
                            <p className="mt-1 text-sm text-gray-600">Password can be reset here.</p>
                        </div>
                    </div>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200"></div>
                        </div>
                    </div>
                    <div className="md:grid flex items-center justify-center">
                        <div className="mt-2 md:mt-0 md:col-span-2">
                        <form method="POST" onSubmit={handleSubmit(submitResetPassword)}>
                            <div className="rounded-lg shadow-xl border overflow-hidden sm:rounded-md m-5 mt-0">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    
                                    <div className="col-span-12">
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        New Password *
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password" {...register('password')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.password?.message}</div>
                                    </div>

                                    <div className="col-span-12">
                                        <label htmlFor="passwordConfirmation" className="block text-sm font-medium text-gray-700">
                                        Confirm Password *
                                        </label>
                                        <input
                                            type="password"
                                            name="passwordConfirmation"
                                            id="passwordConfirmation"  {...register('passwordConfirmation')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.passwordConfirmation?.message}</div>
                                    </div>

                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                                {formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" disabled
                                ><FaSpinner icon="spinner" className="spinner" /> &nbsp;Processing</button> }

                                {!formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >Save</button> }

                                
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
		</>
	);
}