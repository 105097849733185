import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import logo from "../Logo-e1584702777409.png";
import "../styles/css/Global-Style.css";
import "../styles/css/Login.css";
import authActions from "../store/actions/authAction";
import { FaSpinner } from "react-icons/fa";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { addToken } = authActions;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [passworderror, setPasswordError] = useState("");
  const [loginerror, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginForm = (e) => {
    e.preventDefault();
    const formData = { email: email, password: password };
    //console.log(formData);
    if (validateForm(formData) === true) {
      setIsLoading(true);
      axios
        .post(process.env.REACT_APP_API_URL + "auth/local", {
          identifier: email,
          password: password,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.jwt);
          localStorage.setItem("userData", JSON.stringify(response.data.user));
          localStorage.setItem("userid", response.data.user.id);
          /* console.log('User profile', response.data.user);
        console.log('User token', response.data.jwt);
        Cookies.set('token', response.data.jwt, { expires: 1, path: '/' });
        Cookies.set('username', response.data.user.email, { expires: 1, path: '/' });
        Cookies.set('userid', response.data.user.id, { expires: 1, path: '/' }); */
          dispatch(addToken());
          // Set user in AppContext
          //setUser(response.data.user);
          toast.success("Login Successful", {
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
            iconTheme: {
              primary: "#4BB543",
              secondary: "#FFF",
            },
          });
          setIsLoading(false);
          //redirect back to home page
          navigate("/booking");
          //window.location.href = "/booking";
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("An error occurred on Login:", error);
          if (error.response && error.response.status === 400) {
            setLoginError("Please enter valid credentials");
          }
        });
    } else {
      return false;
    }
  };

  function validateForm(datavalidate) {
    //let errors = {};
    let formIsValid = true;
    if (datavalidate.email === "") {
      formIsValid = false;
      setEmailError("Please enter your email address.");
    }

    if (typeof datavalidate.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        setEmailError("Please enter valid email address.");
      }
    }

    if (datavalidate.password === "") {
      formIsValid = false;
      setPasswordError("Please enter your password.");
    }
    return formIsValid;
  }

  function HeaderView() {
    const location = useLocation();
    return location.pathname;
  }

  return (
    <>
      <div className="h-screen flex items-center justify-center  bg-gray-800 md:bg-white">
        <div className=" md:shadow-md md:border md:border-gray-200 md:rounded-lg w-[600px] lg:w-[520px] p-6 md:p-4 lg:p-8 bg-gray-800 border-gray-700">
          <form
            autoComplete="off"
            name="login"
            className="space-y-6"
            onSubmit={loginForm}
          >
            <img src={logo} className="text-center mx-auto" alt="Logo" />
            <div>
              <label
                htmlFor="email"
                className="text-sm font-medium block mb-2 text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bg-gray-300 border border-gray-300 rounded block w-full p-2.5 "
                placeholder="name@company.com"
                required=""
                maxLength={75}
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="font-[14px] pt-[5px] text-[#ff0000]">
                {emailerror}
              </p>
            </div>
            <div>
              <label
                htmlFor="password"
                className="text-sm font-medium block mb-2 text-gray-300"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-300 border border-gray-300 rounded block w-full p-2.5 "
                required=""
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="font-[14px] pt-[5px] text-[#ff0000]">
                {passworderror}
              </p>
            </div>
            <div className="flex items-start">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    aria-describedby="remember"
                    type="checkbox"
                    className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-[#17BA17]-300 h-4 w-4 rounded"
                    required=""
                  />
                </div>
                <div className="text-sm ml-3">
                  <label
                    htmlFor="remember"
                    className="font-medium text-gray-300"
                  >
                    Stay Logged In
                  </label>
                </div>
              </div>
              <a
                href="/forgotPassword"
                className="text-sm text-blue-700 hover:underline ml-auto dark:text-blue-500"
              >
                Forgot Password ?
              </a>
            </div>
            <button
              type="submit"
              className="w-full text-white bg-[#17BA17] hover:bg-[#17BA17]-800 font-medium rounded text-sm px-5 py-2.5 text-center uppercase"
              disabled={isLoading}
            >
              {!isLoading && <span>Login</span>}
              {isLoading && <span className="inline-flex justify-center"><FaSpinner icon="spinner" className="spinner" />{" "}
                &nbsp;Logging In</span>}
            </button>
            <a
              href="/"
              className="block w-full text-white bg-[#17BA17] hover:bg-[#17BA17]-800 font-medium rounded text-sm px-5 py-2.5 text-center uppercase"
            >
              Select Visitor / Member
            </a>
            <div>
              <p className="font-[14px] pt-[5px] text-[#ff0000] text-center">
                {loginerror}
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
