import React, { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import axios from "axios";

let timer = 0;

const CountdownTimer = (props) => {

    const { startTimerFlag, initialSeconds, values, updateReservationSecs } = props;
    const [time, setTime] = useState({});
    const [seconds, setSeconds] = useState(initialSeconds);
    const [timerStarted, setTimerStarted] = useState(false);
    const [showExtensionPopup, setShowExtensionPopup] = useState(false);
    const [extensionCancelled, setExtensionCancelled] = useState(false);
    const [extensionConfirmed, setExtensionConfirmed] = useState(false);
    let __seconds = initialSeconds;

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    };

    const countDown = () => {
        // Remove one second, set state so a re-render happens.
        //console.log('In Countdown', timer)
        __seconds = __seconds - 1;
        setSeconds(__seconds);
        setTime(secondsToTime(__seconds));

        // Check if we're at zero.
        if (__seconds == 0) {
            clearInterval(timer);
        } else if (__seconds <= 240) {
            if (!showExtensionPopup && !extensionCancelled && !extensionConfirmed) {
                setShowExtensionPopup(true);
            }
        }
        //console.log(__seconds, secondsToTime(__seconds));
    }

    const startTimer = () => {
        if (timer == 0 && __seconds > 0) {
            timer = setInterval(() => { countDown() }, 1000);
        }
    }

    const updateReservation = async () => {
        const u_b_id = localStorage.getItem('u_b_id');
        if (!u_b_id) {
            localStorage.setItem("u_b_id", Math.floor(Date.now() / 1000) + Math.random().toString(36).slice(2));
        }

        let reserve_query = values.SelectedSlotId + "/" + u_b_id;
        let reserve_result = await axios
            .get(process.env.REACT_APP_API_URL + "updateReserveSlot/" + reserve_query, {
                headers: { Authorization: process.env.REACT_APP_API_KEY },
            })

        let reservation_result = reserve_result.data.reservation_result;
        // console.log(reservation_result);
        if (!reservation_result.success || reservation_result.success == 0) {
            toast.error(reservation_result.message);
            return false;
        } else {
            clearInterval(timer);
            timer = 0;
            console.log(reservation_result);
            updateReservationSecs(reservation_result.expiry_secs);
            __seconds = reservation_result.expiry_secs;
            setShowExtensionPopup(false);
            setExtensionConfirmed(true);
            setTimerStarted(false);
        }
    };

    /* useEffect(() => {
        let timeLeftVar = secondsToTime(seconds);
        setTime(timeLeftVar);

        return () => {

        }
    }, []); */

    useEffect(() => {
        console.log('Timer Started Before', startTimerFlag, timerStarted)
        if (startTimerFlag && !timerStarted) {
            if (timer) {
                clearInterval(timer);
            }
            console.log('Timer Started')
            setTimerStarted(true);
            let timeLeftVar = secondsToTime(seconds);
            setTime(timeLeftVar);
            startTimer();
        }
    }, [timerStarted, startTimerFlag])

    /* useEffect(() => {
        console.log(seconds, time);

    }, [time, seconds]);
 */



    return (
        <>
            {time && <div className="text-center flex flex-col w-full items-center justify-center mb-2">
                <label className="text-xl">Time Left</label>
                <div className="text-2xl text-center flex w-full items-center justify-center">
                    <div className="w-24 mx-1 p-2 bg-yellow-500 text-white rounded-lg">
                        <div className="font-mono leading-none mb-2" x-text="minutes">{String(time.m).padStart(2, 0)}</div>
                        <div className="font-mono uppercase text-sm leading-none">Minutes</div>
                    </div>
                    <label className='text-yellow-500 font-bold'>:</label>
                    <div className="w-24 mx-1 p-2 bg-yellow-500 text-white rounded-lg">
                        <div className="font-mono leading-none mb-2" x-text="seconds">{String(time.s).padStart(2, 0)}</div>
                        <div className="font-mono uppercase text-sm leading-none">Seconds</div>
                    </div>
                </div>
            </div>}
            {(showExtensionPopup && !extensionCancelled && !extensionConfirmed && seconds > 0) && (
                <>
                    <div
                        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center bg-[#000000ad]"
                        id="modal-id"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-2xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="bg-white p-6  md:mx-auto">
                                    <svg width="24" height="24" viewBox="0 0 1000 1000" className="text-green-600 w-16 h-16 mx-auto my-6"><path fill="currentColor" d="M 500 0C 224 0 0 224 0 500C 0 776 224 1000 500 1000C 776 1000 1000 776 1000 500C 1000 224 776 0 500 0C 500 0 500 0 500 0M 500 25C 762 25 975 238 975 500C 975 762 762 975 500 975C 238 975 25 762 25 500C 25 238 238 25 500 25C 500 25 500 25 500 25M 526 150C 576 150 602 175 601 224C 600 300 600 350 575 525C 570 560 560 575 525 575C 525 575 475 575 475 575C 440 575 430 560 425 525C 400 355 400 300 400 226C 400 175 425 150 475 150M 500 650C 527 650 552 661 571 679C 589 698 600 723 600 750C 600 805 555 850 500 850C 445 850 400 805 400 750C 400 723 411 698 429 679C 448 661 473 650 500 650C 500 650 500 650 500 650" /></svg>
                                    <div className="text-center">
                                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                                            Time Limit Will Expire in {String(time.s).padStart(2, 0)} seconds!
                                        </h3>
                                        <p className="text-gray-600 my-2">
                                            Your reservation will be released soon. Do you still want to continue the booking?
                                        </p>
                                        <div className="py-10 text-center">
                                            <a
                                                className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
                                                onClick={() => { updateReservation(); }}
                                            >
                                                Continue
                                            </a>
                                            <a
                                                className="ml-2 px-12 bg-red-600 hover:bg-red-500 text-white font-semibold py-3"
                                                onClick={() => {
                                                    setShowExtensionPopup(false);
                                                    setExtensionCancelled(true);
                                                }}
                                            >
                                                No Thanks
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hidden opacity-25 fixed inset-0 z-40 bg-black"
                        id="modal-id-backdrop"
                    ></div>
                </>
            )}
            {(seconds == 0) && (
                <>
                    <div
                        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center bg-[#000000ad]"
                        id="modal-id"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-2xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="bg-white p-6  md:mx-auto">
                                    <svg width="24" height="24" viewBox="0 0 1000 1000" className="text-green-600 w-16 h-16 mx-auto my-6"><path fill="currentColor" d="M 500 0C 224 0 0 224 0 500C 0 776 224 1000 500 1000C 776 1000 1000 776 1000 500C 1000 224 776 0 500 0C 500 0 500 0 500 0M 500 25C 762 25 975 238 975 500C 975 762 762 975 500 975C 238 975 25 762 25 500C 25 238 238 25 500 25C 500 25 500 25 500 25M 526 150C 576 150 602 175 601 224C 600 300 600 350 575 525C 570 560 560 575 525 575C 525 575 475 575 475 575C 440 575 430 560 425 525C 400 355 400 300 400 226C 400 175 425 150 475 150M 500 650C 527 650 552 661 571 679C 589 698 600 723 600 750C 600 805 555 850 500 850C 445 850 400 805 400 750C 400 723 411 698 429 679C 448 661 473 650 500 650C 500 650 500 650 500 650" /></svg>
                                    <div className="text-center">
                                        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                                            Time Limit Reached!
                                        </h3>
                                        <p className="text-gray-600 my-2">
                                            Your reservation has been released. Please re-start your booking
                                        </p>
                                        <div className="py-10 text-center">
                                            <a
                                                href="/booking"
                                                className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3"
                                            >
                                                Back to Booking
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hidden opacity-25 fixed inset-0 z-40 bg-black"
                        id="modal-id-backdrop"
                    ></div>
                </>
            )}
        </>
    )
}

export default CountdownTimer