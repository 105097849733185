import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/Header';
import usersService from '../services/usersService';
import toast from 'react-hot-toast';
import { FaSpinner } from "react-icons/fa"

export default function ForgotPassword() {
	
	const [passwordDetails, setPasswordDetails] = useState()
	const [formSubmitted, setFormSubmitted] = useState(false);
    // CurrentPassword: Yup.string().required('The Current Password field is required'),
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('The Email field is required'),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setError, formState, setValue  } = useForm(formOptions);
    const { errors } = formState;

	const updateUserData = (e)=>{
        setPasswordDetails({...passwordDetails , [e.target.name]:e.target.value});
    }

	const requestResetPassword = (user_data) => {
        //e.preventDefault();
        setFormSubmitted(true);
        console.log(user_data);
        
        usersService.ForgotPassword(user_data).then(response => { 
            console.log(response);
            toast.success('Password reset mail sent successfully. Kindly check your inbox or spam.',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
                iconTheme: {
                    primary: '#4BB543',
                    secondary: '#FFF',
                },
            });
            setFormSubmitted(false);
        }).catch(error => {
            console.log("Check User error : ", error);
        });
	};

	useEffect(() => {
        document.title = "Forgot Password - Thorney Park Golf Club";
	  return () => {
		
	  }
	}, [])
	
	


	return (
		<>
        <Header />
            <div className="mt-10 sm:mt-0 v">  
                <div className="m-6 mb-1 sm:px-0">
                    <div className="px-5 text-center">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Forgot Password</h3>
                        <p className="mt-1 text-sm text-gray-600">Password Reset Link would be sent to you on your mail. Please check your inbox or spam after submitting the reset request.</p>
                    </div>
                </div>
                <div className="hidden sm:block" aria-hidden="true">
                    <div className="py-5">
                        <div className="border-t border-gray-200"></div>
                    </div>
                </div>
                <div className="md:grid  flex items-center justify-center">
                    <div className="mt-2 md:mt-0 md:col-span-2">
                    <form method="POST" onSubmit={handleSubmit(requestResetPassword)}>
                        <div className="rounded-lg shadow-xl border overflow-hidden sm:rounded-md m-5 mt-0">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                               
                                <div className="col-span-12">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email *
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email" {...register('email')}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        onChange={updateUserData}
                                    />
                                    <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.email?.message}</div>
                                </div>

                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                            {formSubmitted && <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" disabled
                            ><FaSpinner icon="spinner" className="spinner" /> &nbsp;Processing</button> }

                            {!formSubmitted && <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >Request Password Reset</button> }

                                
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
		</>
	);
}