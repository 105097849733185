import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Header from '../components/Header';
import usersService from '../services/usersService';
import toast from 'react-hot-toast';
import { FaSpinner } from "react-icons/fa"

export default function Profile() {
	
	const user_name = JSON.parse(localStorage.getItem('userData')).email;
	const user_id = localStorage.getItem('userid');
	const [hidepwdblock, setHidePwdBlock] = useState(false);
	const [profileDetails, setProfileDetails] = useState()
	const [formSubmitted, setFormSubmitted] = useState(false);
    const validationSchema = Yup.object().shape({
        FirstName: Yup.string().required('The First name field is required'),
        LastName: Yup.string().required('The Last name field is required'),
        email: Yup.string().email().required('The Email address field is required'),
        MobileNumber: Yup.string().required('The Mobile Number field is required'),
        /* business: Yup.string().required('The Business field is required'), */
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, setError, formState, setValue  } = useForm(formOptions);
    const { errors } = formState;

	const updateProfileData = (e)=>{
        setProfileDetails({...profileDetails , [e.target.name]:e.target.value});
    }

	const saveProfileDetails = (user_data) => {
        //e.preventDefault();
        setFormSubmitted(true);
        //console.log(user_data);
        user_data.MobileNumber = Number(user_data.MobileNumber);
        usersService.updateUserDetails(user_id, user_data).then(response => { 
            console.log(response);
            toast.success('Profile details updated successfully',
            {
                style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
                },
                iconTheme: {
                    primary: '#4BB543',
                    secondary: '#FFF',
                },
            });
            setFormSubmitted(false);
        }).catch(error => {
            console.log("Check User error : ", error);
        });
	};

	useEffect(() => {
        document.title = "My Profile - Thorney Park Golf Club";
		if((typeof user_id != "undefined")) { 
			
            usersService.getUserDetailsByUserId(user_id).then(response => { 
				if(response.data !== undefined) {
					setProfileDetails(response.data);
					setHidePwdBlock(true);
				} 
			}).catch(error => {
				console.log("Check User error : ", error);
			});
		}
	
	  return () => {
		
	  }
	}, [])
	
	


	return (
		<>
        <Header />
		{profileDetails && <>
                <div className="mt-10 sm:mt-0">  
                    <div className="m-6 mb-1 sm:px-0">
                        <div className="px-5">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">My Profile</h3>
                            <p className="mt-1 text-sm text-gray-600">Personal information can be updated here.</p>
                        </div>
                    </div>
                    <div className="hidden sm:block" aria-hidden="true">
                        <div className="py-5">
                            <div className="border-t border-gray-200"></div>
                        </div>
                    </div>
                    <div className="md:grid">
                        <div className="mt-2 md:mt-0 md:col-span-2">
                        <form method="POST" onSubmit={handleSubmit(saveProfileDetails)}>
                            <div className="rounded-lg shadow-xl border overflow-hidden sm:rounded-md m-5 mt-0">
                            <div className="px-4 py-5 bg-white sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-6 sm:col-span-3">

                                        <label htmlFor="FirstName" className="block text-sm font-medium text-gray-700">
                                        First name *
                                        </label>
                                        <input
                                            type="text"
                                            name="FirstName"
                                            id="FirstName"
                                            autoComplete="given-name" value={profileDetails?.FirstName}  {...register('FirstName')}
                                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.FirstName?.message}</div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="LastName" className="block text-sm font-medium text-gray-700">
                                        Last name *
                                        </label>
                                        <input
                                        type="text"
                                        name="LastName"
                                        id="LastName" value={profileDetails?.LastName}
                                        autoComplete="family-name"  {...register('LastName')}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.LastName?.message}</div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address *
                                        </label>
                                        <input
                                        type="text"
                                        name="email"
                                        id="email" value={profileDetails?.email}
                                        autoComplete="email" {...register('email')}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.email?.message}</div>
                                    </div>

                                    <div className="col-span-6 sm:col-span-3">
                                        <label htmlFor="MobileNumber" className="block text-sm font-medium text-gray-700">
                                        Mobile No *
                                        </label>
                                        <input
                                        type="text"
                                        name="MobileNumber"
                                        id="MobileNumber" value={profileDetails?.MobileNumber}
                                        autoComplete="MobileNumber" {...register('MobileNumber')}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        onChange={updateProfileData}
                                        />
                                        <div className="text-right text-red-500 text-xs mt-1 italic first-letter:capitalize">{errors.MobileNumber?.message}</div>
                                    </div>

                                </div>
                            </div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                {formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500" disabled
                                ><FaSpinner icon="spinner" className="spinner" /> &nbsp;Processing</button> }

                                {!formSubmitted && <button
                                type="submit"
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-pink-500 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                >Save</button> }

                                
                                <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" disabled={formSubmitted}
                                >
                                Cancel
                                </button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </>
            }
		</>
	);
}